

.navbar img{
  width:50%
}

.nav-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  z-index: 2;
}

@media screen and (max-width: 1280px) {
  .navbar img{
    width:70%
  }

  .nav-link img {
    width: 100%
  }
}


@media screen and (max-width: 900px) {
  .navbar img{
    width:100%
  }

  .nav-link img {
    width: 400%
  }
}

@media screen and (max-width: 540px) {
  .navbar img{
    width:110%
  }

  .nav-link  {
    width:20%;
  }

  .navbar.collapse {
  flex-wrap: nowrap;
  }
}