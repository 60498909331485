* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}


.gradient__bg {

  background-image: linear-gradient(to right bottom, 
  #0f3160, #152d61, #1d2861, #262260, 
  #2f1b5e, #2d1859, #2b1554, #29124f,
   #1d1346, #12123d, #0a0f33, #050a29);
}

.gradient__text {
  background: #AB47BC;
  background: radial-gradient(circle farthest-side at top center, #AB47BC 26%, #3F51B5 91%);
  background-clip: text;
  -webkit-text-fill-color: transparent;  
  
}


.section__padding {
  padding: 2rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (min-width: 2200px) and (max-width: 2600px) {
  .section__padding {
    padding: 30rem 0rem;
  }
}


@media screen and (max-width: 1450px) {
  .section__padding {
    padding: 15rem 0rem;
  }
}

@media screen and (max-width: 1050px) {
  .section__padding {
    padding: 15rem 0rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 15rem 0rem;
  }
}

@media screen and (max-width: 490px) {
  .section__padding {
    padding: 15rem 0rem;
  }

}
