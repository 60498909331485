.pt-5 {
  overflow: hidden;
}

.slogan {
  width: 90%;
  animation: move-left-to-right 3.5s linear infinite;
}

.nft {
  width: 100%;
}

.mintnow {
  width: 100%;
}


.mintbutton {
  border: 1px solid;
  border-color: #fff;
  padding: 60px;
  background-color: rgb(7, 6, 41);
  width:100%;
}

.mintbutton button {
  background: #0c3794;
  border: 2px solid #0c3794;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 50px;
  line-height: 100px;
  min-height: 50px;
  font-family: var(--font-family);
  width: 60%;
  transition: background-color 0.2s, transform 0.2s;
}


button:hover {
  background-color: #06276e;
  transform: translateY(-3px);
}


@keyframes move-left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }

}

@media screen and (max-width: 540px) {
  .mintbutton button {
    font-size: 30px;
    width: 100%;
  }
}